<template>
  <div class="flow-content">
    <el-timeline class="node-block">
      <el-timeline-item
          placement="bottom"
          v-for="(value,key) in repairNodes" :key="key"
          type="primary"
          :class="getNodeStatus(value.dealType)"
      >
        <div class="timeline-nodeName clearfix">
          <span>{{value.nodeName}}</span>
        </div>
        <div class="timeline-approver">
          <div class="timeline-time" v-if="value.dealTime">{{value.dealTime}}</div>
          <span  v-if="value.dealRemark">{{value.dealRemark}}</span>
          <div class="timeline-reason" v-if="value.dealReason || value.checkReasonPaid || value.checkReasonReceipt">
            <div v-if="value.dealReason">{{value.dealReason}}</div>
            <div v-if="value.checkReasonPaid">商户已缴纳罚金</div>
            <div v-if="value.checkReasonReceipt">罚金收据已开</div>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>

export default {
  name: "order-flow",
  props:['repairNodes', 'type'],
  methods:{
    getHandleStatus(dealtype){
      if(dealtype==1){
        return '(已拒绝)'
      }else if(dealtype==0){
        return '(已通过)'
      }else {
        return '未处理'
      }
    },

    getNodeStatus(dealType){
      switch (dealType) {
        case 0:
          return 'approve';
          break;
        case 1:
          return 'refuse';
          break;
        default:
          return 'default';
          break;
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/pages/propertyModule/style/detail-flow";
</style>