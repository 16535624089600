<template>
  <div>
    <div class="shopownerCheck detail-wrapper">
      <el-row class="message">
        <el-col :span="20"><span>店铺: </span> {{managerApplyInfo.shopName || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>姓名: </span> {{managerApplyInfo.name || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>身份证号: </span> {{managerApplyInfo.idCard || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>电话: </span> {{managerApplyInfo.mobile || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>提交人: </span> {{managerApplyInfo.commitUser || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>提交日期: </span> {{managerApplyInfo.createTime || ''}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>申请理由: </span> {{managerApplyInfo.applyReason || ''}}</el-col>
      </el-row>
    </div>
    <div class="rowBtn">
      <el-button @click="cancel" size="medium">取消</el-button>
      <el-button type="danger"
                 @click="failCheck"
                 v-if="managerApplyInfo.status === 2" size="medium">驳回</el-button>
      <el-button type="primary"
                 @click="byManagerApplyCheck"
                 v-if="managerApplyInfo.status === 2" size="medium">通过</el-button>
    </div>
  </div>

</template>

<script>

export default {
  data () {
    return {
      managerApplyInfo: {}
    }
  },
  computed: {

  },
  components: {

  },
  created () {
    this.getManagerApplyInfo(this.$route.query.id)
  },
  mounted () {
  },
  methods: {
    /**
     * 详情
     */
    getManagerApplyInfo (id) {
      this.$api.checkapi.getManagerApplyInfo(id)
        .then(({ code, msg, data }) => {
          if (code === 200) {
            if (data !== null) {
              this.managerApplyInfo = data
            }
          } else {
            this.$message.error(msg);
          }
        })
    },

    /**
     * 通过
     */
    byManagerApplyCheck () {
      const obj = {
        id: this.$route.query.id,
        status: 1
      }
      this.$api.checkapi.managerApplyUpdate(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.$router.go(-1)
          } else {
            this.$message.error(msg);
          }
        })
    },

    /**
     * 驳回
     */
    failCheck () {
      this.$prompt('请输入驳回理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        const obj = {
          id: this.$route.query.id,
          status: 0,
          rejectReason: value
        }
        this.$api.checkapi.managerApplyUpdate(obj)
          .then(({ code, data, msg }) => {
            if (code === 200) {
              this.$router.go(-1)
            } else {
              this.$message.error(msg);
            }
          })
      }).catch(() => {

      });
    },

    /**
     * 取消
     */
    cancel () {
      this.$router.push({ path: '/checkModule/check/shopowner', query: { page: this.$route.query.page } })
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
@import "src/pages/style/detail.scss";
</style>  
