<template>
  <div>
    <div class="detail-wrapper">
      <el-row class="message"
              v-if="shopData">
        <el-col :span="20"><span>店铺: </span>{{shopData.shopName}}</el-col>
      </el-row>
      <el-row class="message"
              v-if="shopData">
        <el-col :span="8"><span>提交人: </span> {{shopData.commitUser}}</el-col>
        <el-col :span="8"
                :offset="6"><span>提交日期: </span> {{shopData.createTime}}</el-col>
      </el-row>
      <el-row class="message"
              v-if="shopData">
        <el-col :span="20"><span>人像面: </span>
          <el-image :src="shopData.idcardFrontKey"
                    alt=""
                    :preview-src-list="srcList1"
                    @click="previewSrc(shopData.idcardFrontKey,1)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message"
              v-if="shopData">
        <el-col :span="20"><span>国徽面: </span>
          <el-image :src="shopData.idcardContraryKey"
                    alt=""
                    :preview-src-list="srcList2"
                    @click="previewSrc(shopData.idcardContraryKey,2)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message"
              v-if="shopData">
        <el-col :span="20"><span>健康证: </span>
          <el-image :src="shopData.healthCardKey"
                    alt=""
                    :preview-src-list="srcList3"
                    @click="previewSrc(shopData.healthCardKey,3)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message"
              v-if="shopData">
        <el-col :span="20"><span>自拍照: </span>
          <el-image :src="shopData.selfiePhotoKey"
                    alt=""
                    :preview-src-list="srcList4"
                    @click="previewSrc(shopData.selfiePhotoKey,4)"></el-image>
        </el-col>
      </el-row>
    </div>
    <div class="rowBtn">
      <el-button @click="cancel" size="medium">取消</el-button>
      <el-button type="danger"
                 @click="failCheck"
                 v-if="shopData && shopData.status === 2" size="medium">驳回</el-button>
      <el-button type="primary"
                 @click="byRealnameCheck"
                 v-if="shopData && shopData.status === 2" size="medium">通过</el-button>
    </div>
  </div>
</template>

<script>
import DLable from '../../../components/d-table'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList4: [],
      columnData: [
        { prop: 'paymentManner', label: '支付方式' },
        { prop: 'paymentCount', label: '支付笔数' },
        { prop: 'paymentMoney', label: '支付金额' },
      ],
      shopData: {},
      tableData: [{
        paymentManner: '微信',
        paymentCount: '23',
        paymentMoney: '50004',
      }, {
        paymentManner: '支付宝',
        paymentCount: '323',
        paymentMoney: '423432',
      }, {
        paymentManner: '现金',
        paymentCount: '4',
        paymentMoney: '2340',
      }, {
        paymentManner: '银行卡',
        paymentCount: '33',
        paymentMoney: '4390',
      }]
    }
  },
  computed: {

  },
  components: {
    DLable
  },
  created () {
    this.getCardManagerInfo()
  },
  mounted () {
  },
  methods: {
    /**
     * 获取详情
     */
    getCardManagerInfo () {
      this.$api.checkapi.cardManagerInfo(this.$route.query.id)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.shopData = data
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 驳回
     */
    failCheck () {
      this.$prompt('请输入驳回理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        const obj = {
          id: this.$route.query.id,
          status: 0,
          rejectReason: value
        }
        this.$api.checkapi.cardManagerAudit(obj)
          .then(({ code, data, msg }) => {
            if (code === 200) {
              this.$router.go(-1)
            } else {
              this.$message.error(msg);
            }
          })
      }).catch(() => {

      });
    },
    /**
     * 通过
     */
    byRealnameCheck () {
      const obj = {
        id: this.$route.query.id,
        status: 1
      }
      this.$api.checkapi.cardManagerAudit(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.$router.go(-1)
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 取消
     */
    cancel () {
      this.$router.push({ path: '/checkModule/check/papers', query: { page: this.$route.query.page } })
    },

    /**
     * 查看大图
     */
    previewSrc (src, type) {
      this[`srcList${type}`] = [src]
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.el-image{
  width:200px;
}
@import "src/pages/style/detail.scss";
</style>  
