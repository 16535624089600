<template>
  <div class="applyCashierCheck">
    <div class="detail-wrapper">
        <div class="left">
          <div class="item">
              <span>店铺编号：</span>
              <span>{{ info.shopNo }}</span>
          </div>
          <div class="item">
              <span>处理店铺：</span>
              <span>{{ info.shopName }}</span>
          </div>
          <div class="item">
              <span>身份证号：</span>
              <span>{{ info.idCard }}</span>
          </div>
          <div class="item">
              <span>电话：</span>
              <span>{{ info.mobile }}</span>
          </div>
          <div class="item">
              <span>申请理由：</span>
              <span>{{ info.applyReason }}</span>
          </div>
        <div class="item">
            <span>状态：</span>
            <span :style="{ color: statusMapName[info.status].color }">{{ statusMapName[info.status].name }}</span>
        </div>
        </div>
        <div class="right">
          <OrderFlow :repairNodes="orderFlow"/>
        </div>
    </div>
    <div class="rowBtn">
      <el-button size="medium" @click="cancelFun">取消</el-button>
      <el-button type="danger" size="medium" @click="rejectFun" v-if="info.status === 2">驳回</el-button>
      <el-button type="primary" size="medium" @click="agreeFun" v-if="info.status === 2">通过</el-button>
    </div>
    <el-dialog
        title="驳回理由"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="closeFun">
          <el-input type="textarea" :rows="5" maxlength="100" show-word-limit placeholder="请输入驳回理由" v-model="reason"></el-input>
        <div slot="footer">
            <el-button @click="closeFun">取 消</el-button>
            <el-button type="primary" @click="confirmFun">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import OrderFlow from '@/pages/propertyModule/components/order-flow.vue'
const statusMapName = {
  2: {
    name: '待审核',
    color: '#5b69fe'
  },
  4: {
    name: '已通过',
    color: '#48e298'
  },
  3: {
    name: '已驳回',
    color: '#ec808d'
  }
}
export default {
  name: 'ApplyCashierCheck',
  components: { OrderFlow },
  data () {
    return {
      id: this.$route.query.id,
      info: {},
      orderFlow: [], // 工单流程
      dialogVisible: false,
      reason: '',
      statusMapName
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    cancelFun () {
      this.$router.push({ path: '/checkModule/check/cashier' })
    },
    rejectFun () {
      this.dialogVisible = true
    },
    closeFun () {
      this.reason = ''
      this.dialogVisible = false
    },
    confirmFun () {
      const reason = this.reason
      if(!reason) {
        return this.$message.warning('请输入驳回理由')
      }
      this.$api.checkapi.rejectCashierApi({ id: this.id, reason }).then(() => {
        this.actionAfterFun('驳回成功')
      })
    },
    agreeFun () {
      this.$api.checkapi.agreeCashierApi({ id: this.id }).then(() => {
        this.actionAfterFun()
      })
    },
    actionAfterFun (tipstr = '操作成功') {
      this.$message.success(tipstr)
      this.cancelFun()
    },
    getDetail () {
      this.$api.checkapi.detailCashierApi({ id: this.id }).then(({ data }) => {
        this.info = data
        this.orderFlow = data.nodes || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/pages/style/detail.scss";
.detail-wrapper {
  display: flex;
  flex-direction: row !important;
  .left,.right {
    flex: 1;
    overflow-y: auto;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
  }
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .item {
    padding: 20px 0;
    display: flex;
    > span:first-of-type {
      flex-shrink: 0;
      width: 100px;
      text-align: left;
    }
  }
  .medias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    .media_wrap {
      position: relative;
      height: 200px;
    }
    .video_tip {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 40px;
      height: 40px;
      transform: translate3d(-50%, -50%, 0)
    };
    .meida_box {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>